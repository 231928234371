@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
.regions-map svg{
  transform: translate(0px, -72px);
  g {
  fill: #807053;
  stroke-width: 1;
}
}
path {
    cursor: pointer;
  }

  .st5 {
   display: none;
  }
  .st4 {
    display: block;
    stroke: #C9B183;
  }
  tspan{
      stroke: #0000;
  }
  .st4:hover {
    fill: #a99f8c;
  }
  .st4:hover path{
    stroke: #fff;
  }
