@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
path {
    cursor: pointer;
  }
 .map-xerite g {
    fill: #807053;
    stroke: #C9B183;
    stroke-width: 2;
  }
  .st1 {
   display: none;
  }
  .st3 {
    display: block;
    stroke: #C9B183;
    stroke-width: 2;
   }
  .st0 {
    stroke: #C9B183;
    &:nth-child(2n){
      fill: #897c68;
    }
    &:nth-child(3n){
      fill: #7c705d ;
    }
  }
  .st0:hover, .st3:hover {
    fill: #a99f8c;
  }
  .st0:hover path, .st3:hover path{
    stroke: #fff;
    opacity: 0.7;
  }
  .map-xerite {
    svg{
      transform: translate(168px, -12px) scale(1.3);
   }
  }

  @media (max-width: 1537px) {
    .map-xerite {
      transform:scale(0.96) translateY(2px) translateX(-2px);
      svg {
        transform: translate(48px, -72px) scale(1.2);
      }
    }
  }

@media (max-width: 1345px) {
  .map-xerite {
    transform:scale(0.80) translateY(2px) translateX(-2px);
    svg {
      transform: translate(48px, -72px) scale(1.2);
    }
  }
}
